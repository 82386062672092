.article-skeleton {
  &__image {
    width: 100%;
    height: 100%;
  }

  &__c-image-container {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__c-image {
    margin-top: 10px;
    display: block;
    width: 80%;
    height: 250px;
  }
}
