.mobile-menu {
  z-index: 1000;

  &.show {
    animation: show-mobile-menu 0.2s ease-in;
  }

  &.hide {
    animation: hide-mobile-menu 0.2s ease-in;
    transform: translateY(100%);
  }
}

@keyframes show-mobile-menu {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes hide-mobile-menu {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(100%);
  }
}
