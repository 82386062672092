.heading-2 {
  font-size: 24px;
  line-height: 28px;
  font-weight: 900;
  color: #1d1e2c;

  &--40 {
    font-size: 40px;
    line-height: 40px;
    font-weight: 900;
  }

  &--32 {
    font-size: 32px;
    line-height: 36px;
    font-weight: 900;
  }

  &--28 {
    font-size: 28px;
    line-height: 32px;
    font-weight: 900;
  }

  &--24 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 900;
  }

  &--20 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 900;
  }

  &--18 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
  }

  &--16 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
}
