.container {
  position: relative;
  text-align: center;

  &.transparent {
    background: transparent;
    color: #8e8f95;

    &:visited {
      color: #8e8f95 !important;
    }
  }

  &.text-7 {
    background-color: #efeff0;
    border: 1px solid #8e8f95;
    color: #1d1e2c;
  }
}

a.container {
  text-decoration: none;
}

.pendingSkeleton {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
}
