.badge {
  display: inline-block;
  white-space: nowrap;
  border-radius: 7px;
  padding: 2px 6px;
  align-self: self-start;

  p > &,
  h1 > &,
  h2 > &,
  h3 > &,
  h4 > &,
  h5 > &,
  h6 > & {
    &:last-child {
      margin-left: 8px;
    }

    &:first-child {
      margin-right: 8px;
    }
  }
}
