.guideline-card {
  color: white;
  border-radius: 12px;
  background: #1d1e2c;

  &__content {
    padding: 12px 16px 4px;
  }

  &__heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &__description {
    color: rgb(255 255 255 / 80%);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 4px;
    display: inline-block;
    vertical-align: middle;
    white-space: pre-wrap;
  }

  &__button {
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 12px;
    color: white;

    &:hover {
      background-color: #f4f4f4;
    }
  }

  &__actions {
    display: flex;
    gap: 8px;
    padding: 0 8px 8px;
  }
}

.guideline-card-reference {
  display: flex;
  position: absolute;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    box-sizing: content-box;
    min-width: 100%;
    min-height: 100%;
    aspect-ratio: 1 / 1;
    left: 50%;
    top: 50%;
    padding: 3px;
    align-items: center;
    border-radius: 50%;
    background: white;
    transform: translate(-50%, -50%);
  }
}
