.card-2 {
  width: 100%;
  border-radius: 30px;
  border: 1px solid #1d1e2c;
  background: #fff;
  display: flex;
  align-items: center;
  padding: 12px 16px;

  &__content {
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;

    & > *:last-child {
      margin-bottom: 0;
    }
  }

  &__picture {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 16px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
    overflow: hidden;

    & > img,
    & > svg {
      max-width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  &__tagline {
    color: #565661;
    font-size: 12px;
    line-height: 1.33;
    margin-bottom: 4px;
    font-weight: 500;
    text-transform: uppercase;
  }

  &__heading {
    color: #1d1e2c;
    font-size: 20px;
    font-weight: 900;
    line-height: 1.2;
    margin-bottom: 8px;
  }

  &__description {
    color: #1d1e2c;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.43;
    text-decoration-line: underline;
  }
}
