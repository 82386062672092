.skeleton {
  &--fill {
    display: block;
    height: 100%;
    width: 100%;

    & > * {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &--flex {
    flex-grow: 1;
  }
}
