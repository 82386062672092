.text-2 {
  line-height: 28px;
  font-weight: 500;

  &--sat-100 {
    color: #1d1e2c;
  }

  &--sat-75 {
    color: #565661;
  }

  &--sat-50 {
    color: #8e8f95;
  }

  &--sat-25 {
    color: #c7c7ca;
  }

  &--sat-7 {
    color: #efeff0;
  }

  &--sat-5 {
    color: #f4f4f4;
  }

  &--sz-20 {
    font-size: 20px;
    line-height: 28px;
  }

  &--sz-16 {
    font-size: 16px;
    line-height: 24px;
  }

  &--sz-14 {
    font-size: 14px;
    line-height: 20px;
  }

  &--sz-12 {
    font-size: 12px;
    line-height: 16px;
  }

  &--weight-400 {
    font-weight: 400;
  }

  &--weight-500 {
    font-weight: 500;
  }

  &--weight-600 {
    font-weight: 600;
  }

  &--weight-700 {
    font-weight: 700;
  }

  &--weight-800 {
    font-weight: 800;
  }

  &--weight-900 {
    font-weight: 900;
  }
}
