.header {
  background: white;

  // animation: sticky-close 0.1s ease-in;
  &__sticky-stub {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 72px;
  }

  &--sticky {
    position: sticky;
    z-index: 999;
    top: 0;
    left: 0;

    &.show {
      animation: show-sticky-header 0.2s ease-in-out;
    }

    &.hide {
      animation: hide-sticky-header 0.24s ease-in-out;
      transform: translateY(-100%);
    }
  }

  &__logo {
    flex-shrink: 0;
  }
}

@keyframes show-sticky-header {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0%);
  }
}

@keyframes hide-sticky-header {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}
