.container {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img {
  margin-bottom: 20px;

  @media screen and (width > 740px) {
    margin-bottom: 30px;
  }
}

.heading {
  text-align: center;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 600;
  margin-bottom: 14px;
  color: #1d1e2c;

  @media screen and (width > 740px) {
    font-size: 42px;
  }
}

.description {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.5;
  color: #565661;
  margin-bottom: 24px;

  @media screen and (width > 740px) {
    margin-bottom: 30px;
  }
}

.homeBtn {
  margin-bottom: 10px !important;
  margin-top: 0 !important;
  width: 300px !important;
  max-width: 100%;
  font-weight: 600;
}

.historyBtn {
  width: 300px !important;
  max-width: 100%;
  margin-top: 0 !important;
  font-weight: 600;
}
