.rec-feed-group-skeleton {
  .rec-feed-group__wrapper {
    background-color: #e9e9ec;
  }

  & .section-title,
  & .recommendations-sequence-title {
    width: 100%;
  }

  &__recommendation {
    width: 100%;
  }
}
