.rec-feed-group {
  height: 100vh;
  background: white;

  &__container {
    height: 100%;
  }

  &__wrapper {
    height: 100%;
    background: #e2f2f6;
    display: flex;
    flex-direction: column;
    text-align: center;

    @media (width >= 632px) {
      padding: 0;
    }
  }

  &__recs {
    margin-bottom: 16px;

    & .recommendations-sequence-item:last-child {
      margin-top: 0;
    }
  }
}

.r-f-g-rec-pointer {
  height: 48px;
  padding: 0 12px;
  display: inline-block;

  &__arrow {
    animation: rec-feed-group-pointer 0.5s ease-in-out infinite alternate;
  }
}

@keyframes rec-feed-group-pointer {
  0% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(100%);
  }
}
