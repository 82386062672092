.__container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 16px;
  padding-right: 16px;
  max-width: 532px;
  width: 100%;

  &--sm {
    max-width: 432px;
  }

  &--margin {
    padding-left: initial;
    padding-right: initial;
    display: flex;

    &::before {
      content: '';
      width: 16px;
    }

    &::after {
      content: '';
      width: 16px;
    }
  }

  &--left {
    padding-right: 0;
  }

  &--right {
    padding-left: 0;
  }

  .__container--margin.__container--left {
    &::before {
      content: none;
    }
  }

  .__container--margin.__container--right {
    &::after {
      content: none;
    }
  }
}

.__container-padding-right {
  padding-right: 16px;
}

.__container-padding-left {
  padding-left: 16px;
}
