body {
  min-height: 100vh;
  text-rendering: optimizespeed;
  line-height: 1.5;
  font-family: Raleway, sans-serif;
  font-weight: 400;
  font-size: 16px;
}

.recommendations {
  background-color: #1d1e2c;
  background-image: url('../images/icons/logo-background-white.svg');
  background-repeat: repeat;
  background-attachment: fixed;

  &-actions {
    // border-bottom: 1px solid #565661;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 0;
    width: 100%;

    button {
      background-color: transparent;
      border: none;
    }

    &-left {
      min-width: 25%;
      display: flex;
      align-items: center;
    }

    &-center {
      min-width: 34%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      flex: 1;

      h5 {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 17px;
      }
    }

    &-right {
      min-width: 25%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.header {
  background: #fff;
}

.header-menu-link {
  height: 72px;
}

.text {
  margin-top: 16px;
}

.text p {
  margin-top: 16px;
  font-size: 18px;
  line-height: 1.7;
  font-weight: 300;
  overflow-wrap: break-word;
}

.section-recommendations {
  padding-top: 0;
  background: #fff;
  margin-top: 20px;

  &-first {
    margin-top: 0;
  }

  &:first-of-type {
    margin-top: 0;
  }
}

.recommendations-banner {
  width: calc(100% + 32px);
  background: #1d1e2c;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 -16px;
  position: relative;

  &-image {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 220px;

    img {
      object-fit: cover;
    }
  }

  &-audio {
    position: absolute;
    bottom: 0;
    width: 100%;
    justify-content: center;
    display: flex;
    padding: 16px;
    background: linear-gradient(
      180deg,
      rgb(29 30 44 / 0%) 0%,
      rgb(29 30 44 / 50%) 100%
    );

    audio {
      width: 100%;
    }
  }

  video {
    width: 100%;
    height: auto;
    max-height: 220px;
  }
}

.section-wrapper-sequence {
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  background: #e2f2f6;
}

.section-wrapper-wide {
  max-width: 640px;
}

.section-title-recommendations {
  margin-top: 16px;
}

.section-recommendations-sequence {
  height: 100vh;
  padding-bottom: 0;
}

.recommendations-sequence {
  flex: 1;
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-bottom: 70px;

  &-title {
    display: flex;
    justify-content: center;
    padding: 140px 30px 0;

    h2 {
      text-align: center;
    }
  }

  &-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    padding: 0 16px;
  }

  &-item {
    text-align: center;

    &:last-child {
      margin-top: 16px;
    }
  }
}

.popup {
  &-header {
    display: flex;
    padding: 16px 48px 16px 24px;
    align-items: center;
    justify-content: center;
    position: relative;

    &-button {
      appearance: none !important;
      display: inline-block !important;
      border-radius: 50% !important;
      border: none !important;
      outline: none !important;
      margin: 0 !important;
      padding: 0 !important;
      color: #1d1e2c !important;
      cursor: pointer !important;
      touch-action: manipulation !important;
      background: transparent !important;
      transition: transform 0.25s ease 0s !important;
      position: absolute !important;
      right: 16px;

      img {
        display: block;
        fill: none;
        height: 16px;
        width: 16px;
        stroke: currentcolor;
        stroke-width: 3px;
        overflow: visible;
      }
    }

    &-tabs {
      display: flex;
      align-items: stretch;
      border: 1px solid #c7c7ca;
      border-radius: 12px;
      flex: 1;
    }

    &-tab {
      border-right: 1px solid #c7c7ca;
      padding: 8px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &:last-child {
        border-right: none;
        border-radius: 0 11px 11px 0;
      }

      &:first-child {
        border-radius: 11px 0 0 11px;
      }

      &.active {
        background-color: #8acbda;
        color: #fff;
        font-weight: 700;
        flex: 1;
      }
    }

    &-badge {
      width: 8px;
      height: 8px;
      background: #ff3b30;
      border-radius: 6px;
      position: absolute;
      top: 3px;
      right: 3px;
      display: none;

      &.show {
        display: block;
      }
    }
  }

  &-content {
    &-feed {
      margin-top: 0 !important;
      padding-left: 24px !important;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &-title {
        padding-bottom: 8px;
      }
    }

    &-date {
      margin: 20px 0 8px;
      padding: 6px 20px;
      background: #e07a5f;
      color: #fff;
      font-size: 14px;
      font-weight: 700;
      text-align: center;
      border-radius: 40px;
    }

    &-block {
      &-feed {
        padding-bottom: 0 !important;
        margin-top: 16px !important;
      }

      &-item {
        position: relative;
        margin-left: -24px;
        margin-right: -16px;
        padding: 6px 16px 6px 24px;

        &.active {
          background: #c4e5ec;
        }
      }

      &-badge {
        width: 8px;
        height: 8px;
        border-radius: 6px;
        position: absolute;
        top: 11px;
        left: 8px;
        display: none;

        &.orange {
          background: #e07a5f;
        }

        &.green {
          background: #30d158;
        }

        &.grey {
          background: #c7c7ca;
        }

        &.show {
          display: block;
        }
      }
    }
  }
}

@media (width >= 744px) {
  .recommendations-banner {
    margin: 0;
    width: 100%;

    &-image {
      height: 320px;
    }

    video {
      width: 570px;
      max-height: 320px;
    }
  }

  .recommendations-sequence {
    padding-bottom: 90px;

    &-title {
      padding-left: 90px;
      padding-right: 90px;
    }

    &-items {
      padding: 0 90px;
    }
  }

  .section-title-recommendations {
    margin-top: 20px;
  }

  .section-switch {
    padding: 12px 0 16px;
    margin: 0;
  }

  .popup-header {
    padding: 16px 48px;
  }

  .popup-header-tabs {
    width: 500px;
    flex: none;
  }

  .popup-header-tab {
    padding: 8px 24px;
  }

  .popup-content-block-item {
    margin-right: 0;
  }
}
