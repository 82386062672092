$color-green: #30d158;
$color-text-7: #efeff0;

.fullscreen-loader {
  height: 100vh;
  width: 100vw;
}

.code-input {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  input {
    width: 50px;
    height: 56px;
    margin-right: 10px;
    display: inline-block;
    border-radius: 12px;
    border: 1px solid #c7c7ca;
    padding: 5px;
    appearance: none;
    vertical-align: middle;
    font-weight: 500;
    font-size: 30px;
    text-align: center;
  }
}

.inline-link {
  color: #1d1e2c;

  &:hover {
    text-decoration: none;
  }
}

.content-input textarea {
  border-radius: 12px;
  border: 1px solid #c7c7ca;
  appearance: none;
  vertical-align: middle;
  font-weight: 500;
  padding: 5px 16px;
  margin: 0;
  resize: vertical;
  width: 100%;
  min-height: 180px;

  &:focus {
    outline: none;
    border-color: #8e8f95;
  }
}

.header-menu a {
  &:visited {
    color: #1d1e2c;
  }
}

.code-input-input {
  appearance: none;
  appearance: textfield;
}

.date-input__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
  border: 1px solid #c7c7ca;
  border-radius: 12px;
  height: 56px;

  .date-input__input-wrapper {
    margin: 0;
  }

  input {
    font-size: 22px;
    border: 0;
    padding: 0;
    text-align: center;
    height: initial;

    &::placeholder {
      /* Firefox 19+ */
      text-transform: uppercase;
    }
  }

  .date-input__input-separator {
    color: #c7c7ca;
    font-size: 28px;
    margin: 2px;
    position: relative;
    top: 0;
    margin-right: 2px;
  }

  .content-input {
    margin: 0;
  }

  .date-input__input--date,
  .date-input__input--month {
    width: 42px;
  }

  .date-input__input--year {
    width: 60px;
  }

  @media (width >= 744px) {
    input {
      font-size: 22px;
    }
  }
}

.ReactModal__Overlay {
  background: rgb(29 30 44 / 40%);
}

a,
a:visited {
  color: #1d1e2c;
}

.ml-10 {
  margin-left: 10px;
}

.ml-20 {
  margin-left: 20px;
}

.form-error {
  color: #dc3333;
  padding: 0.2em 0;
}

.survey-result-wrap {
  margin: 20px 0;
}

.survey-result-question {
  font-weight: bold;
}

.survey-result-question-wrap {
  list-style: none;
  padding: 5px 0 10px;
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.survey-result-answer {
  padding-top: 0.2em;
  padding-left: 1em;
}

.survey-navigation-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

button {
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.5;
  }
}

.list-arrows-block {
  &.not-interactive {
    pointer-events: none;
  }
}

.select-inner {
  background-color: inherit;
}

.remove-link-underline {
  text-decoration: inherit;
}

.myday-list-item-action {
  &:disabled {
    cursor: auto;
  }
}

.cards {
  & > * {
    margin-bottom: 12px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

ul.unset,
ol.unset {
  padding-left: 0;
  list-style: none;
  margin-top: 0;
}

a.unset {
  text-decoration: none;
}

button.unset {
  padding: 0;
  background: none;
  border: none;
}

.popup-content {
  border-top: none !important;
}

.switch {
  & i {
    flex: 0 0 auto;
  }
}

.header-mobile {
  &__back-btn {
    position: absolute;
    height: 32px;
    width: 23px;
    padding: 5px;
    left: 3px;
  }
}
