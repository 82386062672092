.u-a-documents {
  &__wrapper {
    margin-bottom: 20px;
  }

  &__slider {
    width: 100%;
    z-index: 0;
  }

  &__slider-wrapper {
    display: flex;
    height: 100%;
    width: 100%;
  }

  &__slide {
    margin: 0 10px 0 0;
    width: 160px;
    height: 120px;
    flex: 0 0 auto;

    &:last-child {
      margin-right: 0;
    }

    & .account-tests-download-progress {
      display: flex;
    }

    @media (width >= 744px) {
      width: 210px !important;
      height: 160px;
    }
  }

  &__switch {
    padding: 0 !important;
    margin-bottom: 20px !important;
  }
}

.u-a-d-upload-card {
  color: rgb(28 29 43);
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 110%;

  & .recommendations-item-cover {
    padding: 10px !important;
  }

  button {
    margin-bottom: 4px;
  }

  &:hover {
    & button {
      opacity: 0.7;
    }
  }
}

.u-a-d-card {
  position: relative;
  cursor: pointer;

  &--checked::before {
    position: absolute;
    content: '';
    inset: 0;
    background-color: rgba(29 30 44 / 10%);
    z-index: 1;
  }

  &__check {
    position: absolute;
    left: 50%;
    top: 50%;
    background-color: white;
    border-radius: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    width: 56px;
    aspect-ratio: 1;
    align-items: center;
    justify-content: center;
    z-index: 2;

    & svg {
      stroke: black;
      position: absolute;
      width: 14px;
    }
  }
}
