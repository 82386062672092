.rec-feed {
  &__item {
    &:not(:last-child) > *:last-child {
      margin-bottom: 20px !important;
    }

    padding: 0.05px;
  }

  &__virtual-container {
    width: 100%;
    position: relative;
  }

  &__virtual-list {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  &__virtual-overlay {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
}

.rec-feed-rec {
  &__wrapper {
    position: relative;
  }

  &__stickable-wrapper {
    background: white;
    pointer-events: all;
  }

  &__stickable-container {
    pointer-events: none;
    position: static;
    width: 100%;

    &--sticky {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__stickable-container--sticky > &__stickable-wrapper {
    position: sticky;
    top: 0;
    z-index: 1;
    transition: top 0.2s ease-in-out;
  }
}

.rec-feed-contents-btn {
  position: relative;
  background-color: transparent;
  border: none;
  display: flex;
}

.rec-feed-fav-btn {
  background-color: transparent;
  display: flex;
  border: none;
}

.rec-feed-contents {
  &__tabs {
    border: 1px solid #1d1e2c;
    height: 32px;
  }

  &__tab {
    &:not(:last-child) {
      border-right: 1px solid #1d1e2c;
    }

    padding: 0 10px;
    display: flex;
    align-items: center;
    text-decoration: none;

    &.active {
      color: #1d1e2c;
      font-weight: 600;
      background-color: #c4e5ec;
    }
  }

  & button {
    padding: 0;
  }
}
