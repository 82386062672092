.loader {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  height: 50px;
  width: 50px;

  &__img {
    width: 100%;
    height: 100%;
  }

  &--xl {
    height: 150px;
    width: 150px;
  }

  &--sm {
    height: 25px;
    width: 25px;
  }

  &--m {
    height: 50px;
    width: 50px;
  }

  &:not(:first-child) {
    margin-left: 4px;
  }
}
