$color-text-100: #1d1e2c;
$color-text-75: #565661;
$color-text-50: #8e8f95;
$color-text-25: #c7c7ca;
$color-text-5: #f4f4f4;
$color-primary-100: #3d405b;
$color-primary-50: #9e9fad;
$color-primary-10: #ececef;
$color-primary-25: #cfcfd6;
$color-primary-5: #f5f5f7;
$color-secondary-100: #e07a5f;
$color-secondary-10: #fcf2ef;
$color-secondary-yellow-100: #f2cc8f;
$color-additional-100: #8acbda;
$color-additional-50: #c4e5ec;
$color-additional-25: #e2f2f6;
$color-white: #ffffff;

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
}

/* Set core root defaults */
html {
  scroll-behavior: smooth;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

i,
input,
textarea,
button,
select,
label,
a {
  -webkit-tap-highlight-color: transparent;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

button:focus {
  outline: 0;
}

body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
  color: $color-text-100;
  font-feature-settings:
    'pnum' on,
    'lnum' on;
}

h1,
h2,
h3,
h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 900;
}

.container {
  padding-left: 20px;
  padding-right: 20px;

  &-left {
    padding-left: 20px;
  }
}

.attention {
  background-color: #e07a5f;
  color: #ffffff;
  font-size: 14px;
  border-radius: 10px;
  padding: 0 6px;
}

.header {
  height: 72px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-text-75;
  display: none;

  &-button {
    background: none;
    border: none;
  }

  &-wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-menu {
    display: flex;
    align-items: center;

    &-button {
      margin-left: 20px;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.5;
      }
    }

    &-badge {
      width: 8px;
      height: 8px;
      background: #ff3b30;
      border-radius: 6px;
      position: absolute;
      top: 20px;
      right: -9px;
      display: none;

      &.show {
        display: block;
      }
    }

    &-link {
      color: $color-text-100;
      text-decoration: none;
      margin-left: 32px;
      padding: 24px 0;
      position: relative;

      &:hover {
        opacity: 0.75;
      }

      &:active {
        opacity: 0.5;
      }

      &.active {
        opacity: 1;
        font-weight: 700;
        border-bottom: 2px solid $color-text-100;
      }
    }
  }
}

@media (min-width: 744px) {
  .header {
    display: flex;
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 900px) {
  .header {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.app-header {
  height: 72px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-text-75;
  display: none;

  &-wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-menu {
    display: flex;
    align-items: center;

    &-button {
      margin-left: 20px;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.5;
      }
    }

    &-link {
      color: $color-text-100;
      text-decoration: none;
      margin-left: 32px;
      padding: 24px 0;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.5;
      }

      &.active {
        opacity: 1;
        font-weight: 700;
        border-bottom: 2px solid $color-text-100;
      }
    }
  }
}

@media (min-width: 744px) {
  .app-header {
    display: flex;
    padding-left: 80px;
    padding-right: 80px;
  }
}

.header-mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 8px;
  width: 100%;
  position: relative;
  border-bottom: 1px solid $color-text-75;

  &-logo {
    img {
      width: 22px;
      height: auto;
    }
  }

  &-title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-subtitle {
    font-size: 13px;
  }

  &-back {
    display: flex;
    align-items: center;
    color: $color-text-100;
    text-decoration: none;

    &:active {
      opacity: 0.9;
    }
  }

  &-left {
    min-width: 25%;
  }

  &-center {
    min-width: 34%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    flex: 1;
  }

  &-right {
    min-width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-help {
    height: 26px;
    width: 26px;
    font-weight: 700;
    color: $color-white;
    /* background: $color-text-100; */
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: .5; */
    color: $color-text-100;
    border: 2px solid $color-text-100;
  }

  &-myday {
    justify-content: center;
  }

  &.center {
    justify-content: center;
    flex-direction: column;
  }
}

@media (min-width: 744px) {
  .header-mobile {
    display: none;

    &-back {
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.logo {
  display: flex;
  align-items: flex-end;
  text-decoration: none;
}

.logo img {
  margin-right: 10px;
  display: none;
  height: 24px;
  width: auto;
}

.logo h3 {
  color: $color-text-100;

  font-size: 20px;
  line-height: 0.8;
}

.progress {
  width: 100%;
  height: 4px;
}

.progress-range {
  width: 30%;
  height: 100%;
  background-color: $color-additional-100;
}

.content {
  padding-top: 40px;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;

  &.container {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  max-width: 500px;
}

.content-title {
  font-size: 28px;
  line-height: 32px;
}

.content-subtitle {
  color: $color-text-75;
  font-size: 18px;
  margin-top: 12px;
}

.content-input {
  margin-top: 20px;
}

.content-input input {
  width: 100%;
  height: 56px;
  border-radius: 12px;
  border: 1px solid $color-text-25;
  padding: 0 16px;
  -webkit-appearance: none;
  vertical-align: middle;

  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    text-transform: uppercase;
  }
}

.content-input,
.code-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }
}

.content-input input::placeholder {
  color: $color-text-25;
}

.content-input input:focus {
  outline: none;
  border-color: $color-text-50;
}

.content-button {
  margin-top: 20px;
  height: 56px;
  // background-color: $color-primary-100;
  background-color: $color-text-100;
  padding: 0 16px;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  justify-content: center;
  color: $color-white;
  font-size: 18px;
  border-radius: 16px;
  width: 100%;
  border: none;

  span {
    display: none;
  }
}

.content-button[disabled] {
  pointer-events: none;
  cursor: default;
  background-color: $color-primary-25;
}

.content-button:focus {
  outline: none;
}

.button-primary {
  height: 56px;
  background-color: $color-text-100;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-white;
  font-size: 18px;
  border-radius: 16px;
  width: 100%;
  border: none;
  text-decoration: none;
  cursor: pointer;

  &:active {
    opacity: 0.7;
  }
}

.button-primary[disabled] {
  pointer-events: none;
  cursor: default;
  background-color: $color-primary-25;
}

.button-primary:focus {
  outline: none;
}

.button-primary:visited {
  color: $color-white !important;
}

.button-text {
  height: 32px;
  background-color: transparent;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $color-text-100;
  font-size: 18px;
  border: none;

  &-questionnauire {
    width: 100%;
  }
}

.button-text[disabled] {
  pointer-events: none;
  cursor: default;
  color: $color-text-50;
}

.button-text:focus {
  outline: none;
}

.button-tertiary-white {
  border: 1px solid #1d1e2c;
  width: 56px;
  height: 56px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
}

.header-menu-button-messenger {
  margin-left: 20px;
}

.header-menu-button:active,
.content-button:active,
.logo:active {
  opacity: 0.7;
}

.content-select {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button {
  &-container {
    padding-left: 20px;
    padding-right: 20px;
  }

  &-secondary {
    height: 56px;
    background-color: $color-text-5;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-100;
    font-size: 18px;
    border: 2px solid $color-text-100;
    border-radius: 16px;
    width: 100%;
    text-decoration: none;

    img {
      margin-right: 6px;
    }

    &:focus {
      outline: none;
    }

    &:active {
      opacity: 0.7;
    }

    &-grey {
      height: 56px;
      background-color: $color-text-5;
      padding: 0 16px;
      display: flex;
      align-items: center;
      color: $color-text-100;
      font-size: 18px;
      border-radius: 12px;
      width: 100%;
      border: none;

      img {
        margin-right: 6px;
      }

      &:focus {
        outline: none;
      }

      &:active {
        opacity: 0.7;
      }
    }
  }
}

.select {
  position: relative;
  width: 100%;
  border-radius: 12px;
  background: $color-text-5;
  -webkit-appearance: none;
  vertical-align: middle;
  margin-bottom: 12px;

  &-text {
    &-language {
      font-weight: 700;
    }

    &-country {
      color: $color-text-75;
    }
  }
}

.select input[type='radio'],
.select input[type='checkbox'] {
  position: absolute;
  visibility: hidden;
}

.select-inner {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 54px;
  padding: 12px 16px 12px 62px;
  border-radius: 12px;
  cursor: pointer;
}

.select-input {
  width: 100%;
  background: transparent;
  border: none;
  -webkit-appearance: none;
  padding: 0;
}

.select-input:focus {
  outline: none;
}

.select-radio {
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  left: 16px;
  border: 15px $color-white solid;
  background: transparent;
  // box-shadow: 0 0 0 1px #C7C7CA;
  -webkit-transition:
    border-width 0.1s,
    box-shadow 0.1s;
  -moz-transition:
    border-width 0.1s,
    box-shadow 0.1s;
  -o-transition:
    border-width 0.1s,
    box-shadow 0.1s;
  transition:
    border-width 0.1s,
    box-shadow 0.1s;
}

.select-checkbox {
  border-radius: 8px;
  height: 30px;
  width: 30px;
  left: 16px;
  background: $color-white;
  border: 15px $color-white solid;
  position: absolute;
  -webkit-transition:
    border-color 0.1s,
    background-color 0.1s;
  -moz-transition:
    border-color 0.1s,
    background-color 0.1s;
  -o-transition:
    border-color 0.1s,
    background-color 0.1s;
  transition:
    border-color 0.1s,
    background-color 0.1s;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .select-checkbox-check {
//   width: 16px;
//   height: 12px;
// }

.select-other .select-input {
  display: none;
}

label.select input[type='radio']:checked + .select-inner .select-radio {
  border-width: 5px;
  background: $color-additional-100;
  // box-shadow: 0 0 0 1px #8E8F95;
}

label.select-other input[type='radio']:checked + .select-inner .select-text {
  display: none;
}

label.select-other input[type='radio']:checked + .select-inner .select-input {
  display: inline-block;
}

label.select input[type='radio']:checked + .select-inner {
  background: $color-additional-25;
}

label.select input[type='checkbox']:checked + .select-inner .select-checkbox {
  border-width: 5px;
  background: $color-additional-100;
}

label.select-other input[type='checkbox']:checked + .select-inner .select-text {
  display: none;
}

label.select-other
  input[type='checkbox']:checked
  + .select-inner
  .select-input {
  display: inline-block;
}

label.select input[type='checkbox']:checked + .select-inner {
  background: $color-additional-25;
}

@media (min-width: 744px) {
  .container {
    padding-left: 0;
    padding-right: 0;

    &-left {
      padding-left: 0;
    }
  }

  .header {
    height: 72px;

    &.container {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .content {
    &.container {
      padding-left: 80px;
      padding-right: 80px;
    }
  }

  .button {
    &-container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .progress {
    height: 8px;
  }

  .logo img {
    display: block;
  }

  .content-wrapper {
    max-width: 500px;
  }

  .content-title {
    font-size: 32px;
    line-height: 36px;
  }

  .logo {
    text-decoration: none;
  }

  .logo h3 {
    color: $color-text-100;
  }
}

@media screen and (min-width: 744px) {
  .header-menu-button,
  .content-button,
  .button-secondary-grey,
  .button-primary,
  .button-secondary,
  .logo {
    cursor: pointer;
  }

  .header-menu-button:hover,
  .content-button:hover,
  .button-secondary-grey:hover,
  .button-secondary:hover,
  .button-primary:hover,
  .logo:hover {
    opacity: 0.7;
  }

  .header-menu-button:active,
  .content-button:active,
  .button-secondary-grey:active,
  .button-secondary:active,
  .button-primary:active,
  .logo:active {
    opacity: 0.9;
  }

  // .select:hover .select-radio {
  //   box-shadow: 0 0 0 1px #8E8F95;
  // }

  .select:hover .select-inner {
    background: #f4f4f4;
  }

  // .select:hover .select-checkbox {
  //   border: 1px solid #8E8F95;
  // }
}

.content-wrapper {
  width: 100%;
}

.content-input-label {
  color: #565661;
  font-size: 14px;
}

.signin-password {
  margin-top: 12px;
  position: relative;
}

.new-password {
  position: relative;
}

.signin-password input {
  padding-right: 60px;
}

.signin-password img,
.new-password img {
  position: absolute;
  right: 16px;
  bottom: 14px;
  width: 28px;
  height: 28px;
}

.signin-password-hide,
.new-password-hide {
  opacity: 0.25;
}

.signin-password-hide:active,
.new-password-hide:active {
  opacity: 1;
}

.signin-password-show,
.new-password-show {
  opacity: 1;
}

.signin-password-show:active,
.new-password-show:active {
  opacity: 0.25;
}

.content-button-secondary {
  display: flex;
  width: 100%;
  color: $color-text-100;
  font-size: 18px;
  margin-top: 20px;
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 24px;
}

.content-button-secondary:active {
  opacity: 0.7;
}

.content-button-secondary:focus {
  outline: none;
}

.survey-navigation-button {
  width: 100%;
  color: $color-text-100;
  font-size: 18px;
  margin-top: 20px;

  text-align: center;
  line-height: 24px;
}

.survey-navigation-button:active {
  opacity: 0.7;
}

@media (min-width: 744px) {
  .logo {
    text-decoration: none;
  }

  .signin-password-hide:hover,
  .new-password-hide:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  .signin-password-hide:active,
  .new-password-hide:active {
    opacity: 1;
  }

  .signin-password-show:hover,
  .new-password-show:hover {
    cursor: pointer;
    opacity: 0.75;
  }

  .signin-password-show:active,
  .new-password-show:active {
    opacity: 0.25;
  }

  .content-button-secondary:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .content-button-secondary:active {
    opacity: 0.9;
  }

  .survey-navigation-button:hover {
    cursor: pointer;
    opacity: 0.7;
  }

  .survey-navigation-button:active {
    opacity: 0.9;
  }
}

// MOBILE MENU

.mobile-menu {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
  padding-top: 6px;
  padding-bottom: calc(2px + env(safe-area-inset-bottom));
  backdrop-filter: blur(2px);
  opacity: 0.99;
  border-top: 1px solid $color-text-75;
  padding-left: 2px;
  padding-right: 2px;

  &-image {
    display: flex;
    height: 23px;
    align-items: center;
  }

  &-icon {
    &-active {
      display: none;
    }
  }

  &-text {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: center;
  }

  &-badge {
    width: 8px;
    height: 8px;
    background: #ff3b30;
    border-radius: 6px;
    position: absolute;
    right: 15px;
    top: -2px;
    display: none;

    &.show {
      display: block;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0.9;
    color: $color-text-100;
    text-decoration: none;
    justify-content: center;
    width: 70px;
    //height: 44px;
    position: relative;

    img {
      margin-bottom: 4px;
    }

    &-title {
      font-size: 10px;
      display: none;
    }

    &.active {
      opacity: 1;
      font-weight: 700;

      .mobile-menu-icon {
        display: none;
      }

      .mobile-menu-icon-active {
        display: block;
      }
    }
  }
}

@media (min-width: 744px) {
  .mobile-menu {
    display: none;
  }
}

// MOBILE HEADER

.mobile-header-empty {
  height: 72px;
  width: 100%;
}

.mobile {
  &-search {
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;

    img {
      position: absolute;
      left: 12px;
    }

    input {
      width: 100%;
      height: 36px;
      border-radius: 18px;
      border: none;
      padding: 0 16px 0 36px;
      -webkit-appearance: none;
      vertical-align: middle;
      background-color: $color-primary-10;

      &::placeholder {
        color: $color-text-25;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

@media (min-width: 744px) {
  .mobile-header-empty {
    display: none;
  }
}

.breadcrumbs {
  display: none;

  &-header {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    padding-left: 20px;
    padding-right: 20px;
    min-height: 72px;
    width: 100%;
    justify-content: center;
    flex-direction: column;
    padding-top: calc(16px + env(safe-area-inset-top));

    &-item {
      font-size: 14px;

      &:first-child {
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
      }
    }
  }
}

// .show {
//   display: block !important;

//   &-flex {
//     display: flex !important;
//   }
// }

.account {
  &-report {
    &-info {
      font-size: 14px;
      color: $color-text-75;
      margin-top: 8px;
    }

    &-subtitle {
      a {
        text-decoration: underline;
        color: $color-text-75;
      }
    }

    &-list {
      &-block {
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }

  &-main {
    padding: 0 0 100px 20px;
    display: flex;
    justify-content: center;

    // .account-title {
    //   display: none;
    // }
  }

  &-tests {
    padding: 10px 0 100px;
    display: flex;
    justify-content: center;

    &-download {
      // border: 1px solid $color-primary-10;
      border: none;

      &-cover {
        align-items: center;
        justify-content: center !important;
        padding: 0 !important;
        position: relative;
      }

      &-button {
        border: none;
        width: 56px;
        height: 56px;
        background: $color-text-100;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
      }

      &-progress {
        border: none;
        width: 56px;
        height: 56px;
        background: $color-text-5;
        // display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        cursor: pointer;
        position: absolute;
        display: none;
      }

      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    &-progress {
      display: none;
      align-items: center;
      justify-content: space-between;

      &-left {
        display: flex;
        align-items: center;

        img {
          margin-right: 6px;
        }
      }

      &-right {
        font-size: 14px;
        text-decoration-line: underline;
        opacity: 0.7;

        &:active {
          opacity: 0.5;
        }
      }

      &.show {
        display: flex;
      }
    }

    &-title {
      padding: 0 20px;
    }

    &-upload {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      &-checked {
        position: absolute;
        top: 13px;
        left: 13px;
        display: none;

        &.show {
          display: block;
        }
      }

      &-item {
        width: 42px;
        height: 42px;
        margin-right: 16px;
        border-radius: 21px;
        position: relative;
        background: center / contain no-repeat;
        margin-bottom: 20px;

        &:last-child {
          margin-right: 0;
        }

        svg {
          position: relative;
          width: 42px;
          height: 42px;
          transform: rotate(270deg);
        }

        svg circle:nth-child(1) {
          fill: rgba(218, 218, 218, 0.5);
          stroke-dashoffset: 0;
        }

        svg circle:nth-child(2) {
          fill: none;
          stroke-width: 2px;
          stroke: rgba(29, 30, 44, 0.75);
          stroke-linecap: round;
          stroke-dasharray: 100;
        }
      }
    }

    &-prepare {
      margin-top: 50px;

      &-title {
        padding: 0 20px;
        line-height: 28px;
        margin-bottom: 6px;
      }
    }

    &-action {
      margin: 40px 20px 0;
      padding: 16px 20px 0;
      background: #e2f2f6;
      border-radius: 7px;

      &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      &-title {
        line-height: 28px;
      }

      &-info {
        font-size: 12px;
        font-weight: 700;
        width: 16px;
        height: 16px;
        border: 1px solid $color-text-100;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        min-width: 16px;
      }

      &-text {
        font-size: 14px;
        opacity: 0.75;
        margin-top: 8px;
      }

      &-button {
        margin-top: 12px;
        background: #e26b88;
        border: none;
        color: $color-white;
        border-radius: 22px;
        padding: 5px 20px 7px;
        height: 44px;
        font-size: 18px;
        //font-weight: 500;
        margin-bottom: 20px;
      }
    }

    &-tabs {
      margin-left: 20px;
      display: flex;
      border-bottom: 1px solid rgba(29, 30, 44, 0.05);
    }

    &-tab {
      font-size: 14px;
      margin-right: 20px;
      opacity: 0.75;
      padding: 10px 0;

      &.active {
        opacity: 1;
        font-weight: 700;
        border-bottom: 2px solid $color-text-100;
      }
    }

    &-segments {
      display: flex;
      align-items: center;
      margin-top: 12px;
      overflow-x: scroll;
      -ms-overflow-style: none;
      /* IE and Edge */
      scrollbar-width: none;

      &:first-child {
        margin-left: 20px;
      }

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &-segment {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 18px;
      margin-right: 10px;
      background-color: rgba(61, 64, 91, 0.1);
      border-radius: 16px;
      height: 32px;
      font-size: 14px;

      &:first-child {
        margin-left: 20px;
      }

      &.active {
        background-color: rgba(138, 203, 218, 0.5);
      }
    }

    &-list {
      padding-top: 40px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      padding-left: 20px;

      &.account-report-list {
        padding-top: 16px;
      }

      // &:first-child {
      //   padding-top: 40px;
      // }

      &-title {
        display: flex;
        align-items: center;

        img {
          margin-right: 6px;
        }
      }

      &-status {
        background-color: $color-secondary-100;
        color: $color-white;
        font-size: 12px;
        border-radius: 10px;
        padding: 0 6px;
        display: none;

        &.show {
          display: block;
        }
      }

      &.recommended {
        padding-top: 50px;
      }

      &.prepared {
        padding-top: 0;
      }

      &-right {
        display: flex;
        align-items: center;

        &-block {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;

          span {
            position: absolute;
            font-size: 14px;
            //font-weight: 500;
          }
        }
      }

      &-progress {
        font-size: 14px;
        min-width: 55px;
        height: 20px;
        border-radius: 10px;
        display: flex;
        align-items: stretch;

        &-left {
          height: 100%;
          background-color: rgba(224, 122, 95, 0.6);
          width: 30%;
          border-radius: 10px 0 0 10px;
        }

        &-right {
          height: 100%;
          background-color: rgba(224, 122, 95, 0.25);
          width: 70%;
          border-radius: 0 10px 10px 0;
        }
      }

      &-date {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 6px;
      }

      &-heading {
        font-size: 16px;
        margin-bottom: 6px;
        font-weight: 700;
        padding-right: 20px;

        img {
          display: inline;
          margin-right: 4px;
        }
      }

      &-title {
        padding-right: 20px;
        flex: 1;
      }

      &-block {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        border-bottom: 1px solid $color-text-5;
        min-height: 60px;

        &.last {
          border-bottom: none;
        }

        &.prepared {
          margin-left: 0;
        }

        &.done {
          padding-right: 20px;
        }
      }

      &-arrow {
        opacity: 0.5;
        color: $color-text-100;
        padding-right: 20px;
        margin-left: 8px;
      }

      &-icon {
        min-width: 42px;
        width: 42px;
        height: 42px;
        background-color: #f2becb;
        border-radius: 21px;

        &.ultrasound {
          background-color: #d3d1ed;
        }
      }

      &-item {
        display: flex;
        min-height: 60px;
        align-items: center;

        &-value {
          font-weight: 700;
          font-size: 14px;
        }

        &-color {
          height: 24px;
          width: 20px;
          margin-left: 10px;

          &.optimal {
            background-image: url('../images/icons/marker-optimal.svg');
          }

          &.normal {
            background-image: url('../images/icons/marker-normal.svg');

            &-up {
              background-image: url('../images/icons/marker-normal-up.svg');
            }

            &-low {
              background-image: url('../images/icons/marker-normal-low.svg');
            }
          }
        }
      }
    }

    &-nav {
      padding-top: 10px;
      display: flex;
      flex-direction: column;
    }
  }

  &-wrapper {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-title {
    display: none;
    font-size: 24px;
    line-height: 28px;

    &.container {
      padding-left: 20px;
      padding-right: 20px;
    }

    &-tests {
      display: none;
    }
  }

  &-subtitle {
    font-size: 14px;
    color: $color-text-75;
    margin-top: 6px;

    &.container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 30px;

    &-item {
      display: flex;
      height: 60px;
      align-items: center;

      &:hover {
        cursor: pointer;

        .account-menu-item-arrow {
          opacity: 1;
        }
      }

      &-block {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-left: 20px;
        border-bottom: 1px solid $color-text-5;
        height: 60px;

        &.last {
          border-bottom: none;
        }
      }

      &-arrow {
        opacity: 0.5;
        color: $color-text-100;
        padding-right: 20px;
      }

      &-icon {
        position: relative;
        width: 25px;
      }

      &-notification {
        width: 8px;
        height: 8px;
        border-radius: 4px;
        background: #e07a5f;
        position: absolute;
        top: -7px;
        right: -7px;
        display: none;

        &.show {
          display: inline;
        }
      }

      &-language {
        height: 42px;
        width: 42px;
        margin-right: 10px;
        border-radius: 21px;
        background: rgba(61, 64, 91, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: initial;
        cursor: pointer;

        &.active {
          background: rgba(138, 203, 218, 0.5);
          //font-weight: 500;

          &:hover {
            background: #3d405b;
            pointer-events: none;
          }
        }

        &:hover {
          background: rgba(61, 64, 91, 0.25);
        }
      }

      &.first {
        border-bottom: 1px solid rgba(29, 30, 44, 0.05);
        align-items: flex-start;

        &:hover {
          cursor: initial;
        }
      }

      &.last {
        border-bottom: 1px solid rgba(29, 30, 44, 0.05);
      }
    }
  }
}

@media (min-width: 744px) {
  .account-title-tests {
    display: block;
  }

  .breadcrumbs {
    display: flex;
    font-size: 14px;

    margin-bottom: 6px;

    &-item {
      opacity: 0.75;
      margin-right: 8px;
      color: $color-text-100;
      text-decoration: none;

      &::after {
        content: ' >';
      }

      &:hover {
        opacity: 0.9;
      }

      &:active {
        opacity: 0.7;
      }
    }

    &-header {
      display: none;
    }
  }

  .account-menu-item-arrow {
    padding-right: 0;
  }

  .account-main {
    padding: 50px 0 100px;

    .account-title {
      display: block;
    }
  }

  .account-tests {
    padding: 50px 0 100px;

    &-progress {
      &-right {
        &:hover {
          cursor: pointer;
          opacity: 1;
        }

        &:active {
          opacity: 0.5;
        }
      }
    }

    &-title {
      padding: 0;
    }

    &-prepare {
      &-title {
        padding: 0;
      }
    }

    &-action {
      margin: 40px 0 0;

      &-button,
      &-info {
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        &:active {
          opacity: 0.5;
        }
      }
    }

    &-tabs {
      margin-left: 0;
    }

    &-tab {
      cursor: pointer;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.5;
      }
    }

    &-nav {
      padding-top: 22px;
    }

    &-segment {
      cursor: pointer;

      &:hover {
        background-color: rgba(61, 64, 91, 0.2);
      }

      &:active {
        background-color: rgba(61, 64, 91, 0.1);
      }

      &:first-child {
        margin-left: 0;
      }
    }

    &-list {
      padding-left: 0;

      &-arrow {
        padding-right: 0;
      }

      &-item {
        &:hover {
          cursor: pointer;

          opacity: 0.7;
        }

        &:active {
          opacity: 0.5;
        }
      }

      &-block {
        &.done {
          padding-right: 0;
        }
      }
    }
  }

  // .account-menu {
  //   padding-top: 40px;
  // }

  .account-title {
    font-size: 32px;
    line-height: 36px;

    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .account-subtitle {
    &.container {
      padding-left: 0;
      padding-right: 0;
    }
  }
}

.app-header {
  height: 72px;
  padding-left: 20px;
  padding-right: 20px;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-text-50;
  display: none;

  &-wrapper {
    width: 100%;
    max-width: 1600px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-menu {
    display: flex;
    align-items: center;

    &-button {
      margin-left: 20px;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.5;
      }
    }

    &-link {
      color: $color-text-100;
      text-decoration: none;
      margin-left: 32px;
      padding: 24px 0;
      opacity: 0.75;

      &:hover {
        opacity: 1;
      }

      &:active {
        opacity: 0.5;
      }

      &.active {
        opacity: 1;
        font-weight: 700;
        border-bottom: 2px solid $color-text-100;
      }
    }
  }
}

@media (min-width: 744px) {
  .app-header {
    display: flex;
    padding-left: 80px;
    padding-right: 80px;
  }
}

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  border-bottom: 1px solid $color-text-50;

  &-title {
    font-size: 17px;
    line-height: 22px;
    font-weight: 700;
  }

  h6 {
    font-size: 17px;
    line-height: 22px;
    font-weight: 700;
  }

  &-subtitle {
    font-size: 13px;
  }

  &-back {
    margin: -15px;
  }

  &-help {
    height: 26px;
    width: 26px;
    font-weight: 700;
    color: $color-white;
    /* background: $color-text-100; */
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* opacity: .5; */
    color: $color-text-100;
    border: 2px solid $color-text-100;
  }

  &-myday {
    justify-content: center;
  }

  &-center {
    justify-content: center;
    flex-direction: column;
  }
}

@media (min-width: 744px) {
  .mobile-header {
    display: none;
  }
}

.popup {
  display: none;

  &.show {
    display: block;
  }

  &-progress {
    display: none;

    &.show {
      display: block;
    }
  }

  &-background {
    z-index: 2000;
    position: fixed;
    inset: 0px;
    overflow-y: auto;
    background: $color-text-100;
    animation-duration: 400ms !important;
    animation-iteration-count: 1 !important;
    animation-fill-mode: both !important;
    animation-name: keyframe-popup-background !important;
  }

  &-block {
    -webkit-box-pack: center !important;
    -webkit-box-align: end !important;
    -webkit-box-direction: normal !important;
    -webkit-box-orient: horizontal !important;
    z-index: 2000 !important;
    position: fixed !important;
    inset: 0px !important;
    max-height: 100vh !important;
    padding-top: 12px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-end !important;
    justify-content: center !important;

    &-background {
      -webkit-box-direction: normal !important;
      -webkit-box-orient: vertical !important;
      background: rgb(255, 255, 255) !important;
      position: relative !important;
      width: 100vw !important;
      max-width: 100vw !important;
      max-height: 100% !important;
      display: flex !important;
      flex-direction: column !important;
      box-shadow: rgba(0, 0, 0, 0.3) 0px 8px 28px !important;
      border-top-left-radius: 12px !important;
      border-top-right-radius: 12px !important;
      animation-duration: 400ms !important;
      animation-iteration-count: 1 !important;
      animation-fill-mode: both !important;
      animation-name: keyframe-popup-slide !important;
    }
  }

  &-close {
    position: absolute !important;
    display: flex !important;
    top: 24px !important;
    left: 24px !important;
    z-index: 1 !important;

    &-button {
      appearance: none !important;
      display: inline-block !important;
      border-radius: 50% !important;
      border: none !important;
      outline: none !important;
      margin: 0px !important;
      padding: 0px !important;
      color: $color-text-100 !important;
      cursor: pointer !important;
      touch-action: manipulation !important;
      position: relative !important;
      background: transparent !important;
      transition:
        -ms-transform 0.25s ease 0s,
        -webkit-transform 0.25s ease 0s,
        transform 0.25s ease 0s !important;

      img {
        display: block;
        fill: none;
        height: 16px;
        width: 16px;
        stroke: currentcolor;
        stroke-width: 3px;
        overflow: visible;
      }
    }
  }

  &-delete {
    position: absolute !important;
    display: flex !important;
    top: 20px !important;
    right: 24px !important;
    z-index: 1 !important;

    button {
      appearance: none !important;
      display: inline-block !important;
      border-radius: 50% !important;
      border: none !important;
      outline: none !important;
      margin: 0px !important;
      padding: 0px !important;
      color: $color-text-100 !important;
      cursor: pointer !important;
      touch-action: manipulation !important;
      position: relative !important;
      background: transparent !important;
      transition:
        -ms-transform 0.25s ease 0s,
        -webkit-transform 0.25s ease 0s,
        transform 0.25s ease 0s !important;
      background: none;
      font-size: 18px;
    }
  }

  &-title {
    font-size: 24px;
    line-height: 28px;
  }

  &-file {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 24px;
  }

  &-content {
    margin-top: 64px !important;
    overflow: auto !important;
    padding: 0px 16px !important;
    height: 85vh !important;
    max-height: 875px !important;
    border-top: 1px solid $color-text-5 !important;
    display: flex;
    justify-content: center;

    &-block {
      font-size: 14px !important;
      line-height: 18px !important;
      margin-top: 24px !important;
      padding-bottom: 24px !important;
      width: 100%;
      max-width: 500px;

      &-title {
        font-size: 24px;
        line-height: 32px;
        padding-bottom: 24px;
      }

      &-list {
        display: flex;
        flex-direction: column;
        align-items: stretch;
      }
    }
  }
}

@keyframes keyframe-popup-background {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0.7;
  }
}

@keyframes keyframe-popup {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes keyframe-popup-slide {
  0% {
    opacity: 0;
    transform: translate(0px, 100%);
  }

  100% {
    opacity: 1;
    transform: none;
  }
}

@media (min-width: 744px) and (min-height: 475px) {
  .popup {
    &-block {
      -webkit-box-align: center !important;
      padding: 40px !important;
      align-items: center !important;

      &-background {
        width: 100% !important;
        max-width: 1032px !important;
        border-radius: 12px !important;
        animation-name: keyframe-popup !important;
      }
    }
  }
}

@media (min-width: 744px) {
  .popup {
    &-content {
      &-block {
        &-title {
          font-size: 28px;
          line-height: 36px;
        }
      }
    }
  }
}

.list-arrows {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-block {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $color-text-5;
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: 60px;

    &-left {
      display: flex;
      align-items: center;
      padding-right: 20px;
      flex: 1;
    }

    &-title {
      line-height: 1.3;
    }

    &-right {
      display: flex;
      align-items: center;
    }

    &-arrow {
      opacity: 0.5;
      padding-right: 20px;
      margin-left: 8px;
    }
  }
}

.list {
  margin-right: -20px;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-title {
    margin-bottom: 6px;
    font-weight: 700;
    padding-right: 20px;
    line-height: 22px;

    img {
      display: inline;
      margin-right: 6px;
    }
  }

  &-block {
    margin-top: 40px;

    &:first-child {
      margin-top: 24px;
    }
  }

  &-order {
    width: 18px;
    height: 18px;
    background-color: $color-secondary-100;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    margin-right: 6px;
  }

  &-item {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    border-bottom: 1px solid $color-text-75;
    padding-top: 14px;
    padding-bottom: 14px;
    min-height: 60px;
    text-decoration: none;
    color: $color-text-100;

    &:visited {
      color: $color-text-100;
    }

    &-left {
      display: flex;
      align-items: center;
      padding-right: 20px;
      flex: 1;
    }

    &-icon {
      width: 22px;
      margin-right: 12px;
    }

    &-title {
      line-height: 1.3;
    }

    &-right {
      display: flex;
      align-items: center;
    }

    &-status {
      background-color: $color-secondary-100;
      color: $color-white;
      font-size: 12px;
      border-radius: 10px;
      padding: 0 6px;
      display: none;

      &.show {
        display: block;
      }
    }

    &-arrow {
      opacity: 0.5;
      padding-right: 20px;
      margin-left: 8px;
    }

    &-value {
      font-weight: 700;
      font-size: 14px;

      &-color {
        height: 24px;
        width: 20px;
        margin-left: 10px;

        &.optimal {
          background-image: url('../images/icons/marker-optimal.svg');
        }

        &.normal {
          background-image: url('../images/icons/marker-normal.svg');

          &-up {
            background-image: url('../images/icons/marker-normal-up.svg');
          }

          &-low {
            background-image: url('../images/icons/marker-normal-low.svg');
          }
        }
      }
    }

    &-order {
      margin-right: 10px;

      &-number {
        width: 18px;
        height: 18px;
        background-color: $color-text-100;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 14px;
      }

      &-check {
        display: none;
      }
    }

    &.checked {
      .list-item-order-check {
        display: block;
      }

      .list-item-order-number {
        display: none;
      }

      .list-item-right {
        display: none;
      }

      .list-item-title {
        color: $color-text-50;
      }
    }

    &.inactive {
      .list-item-order-number {
        background-color: $color-text-25;
      }

      .list-item-title {
        color: $color-text-50;
      }

      .list-item-right {
        display: none;
      }
    }
  }
}

.section {
  padding: 16px 16px 100px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.survey {
    margin-top: 20px;
  }

  &-wrapper {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    &-400 {
      max-width: 400px;
    }
  }

  &-block {
    margin-top: 40px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-banner {
    height: 260px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: -16px -16px 16px;
    border-bottom: 1px solid $color-text-75;

    img {
      object-fit: cover;
      width: 100%;
    }

    &--audio {
      width: calc(100% + 32px);
      background: $color-text-75;
      padding-top: 24px;
      padding-bottom: 20px;
      justify-content: center;
      margin-bottom: 16px;

      audio {
        width: calc(100% - 32px);
      }
    }

    &--video {
      width: calc(100% + 32px);
      background: #1d1e2c;
      height: initial;

      video {
        width: 100%;
        height: auto;
        max-height: 500px;
      }
    }
  }

  &-article {
    &-audio {
      width: calc(100% + 32px);
      background: $color-text-75;
      padding-top: 16px;
      padding-bottom: 16px;
      justify-content: center;
      margin-bottom: 16px;
      display: flex;
      margin-top: -16px;

      audio {
        width: calc(100% - 32px);
        max-width: 500px;
      }
    }
  }

  &-date {
    font-size: 16px;
    font-weight: 700;
    display: flex;
    align-items: center;

    img {
      display: inline;
      margin-right: 8px;
    }
  }

  &-switch {
    display: flex;
    align-items: center;
    padding: 12px 16px 16px;
    margin: 0 -16px;
    border-bottom: 1px solid $color-text-75;

    &-question {
      margin-left: 8px;

      &:active {
        opacity: 0.8;
      }
    }
  }

  &-title {
    font-size: 24px;
    line-height: 27px;

    &.desktop {
      display: none;
    }
  }

  &-subtitles {
    margin-top: 8px;

    &.date {
      margin-top: 4px;
    }
  }

  &-subtitle {
    font-size: 14px;
    color: $color-text-75;

    a {
      color: $color-text-75;
      text-decoration: underline;
    }
  }

  &.account-reports-report {
    .section-wrapper {
      .list-arrows {
        margin-top: 12px;

        &-block {
          &-title {
            &.bold {
              font-weight: 700;
            }
          }
        }
      }
    }

    .stages {
      margin-top: 50px;

      .list-arrows {
        margin-top: 8px;

        &-order {
          margin-right: 10px;

          &-number {
            width: 18px;
            height: 18px;
            background-color: $color-text-100;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
          }
        }

        .list-arrows-order-check {
          display: none;
        }

        .list-arrows-block {
          &.checked {
            .list-arrows-order-check {
              display: block;
            }

            .list-arrows-order-number {
              display: none;
            }

            .list-arrows-block-right {
              display: none;
            }

            .list-arrows-block-title {
              color: $color-text-50;
            }
          }

          &.inactive {
            .list-arrows-order-number {
              background-color: $color-text-25;
            }

            .list-arrows-block-title {
              color: $color-text-50;
            }

            .list-arrows-block-right {
              display: none;
            }
          }
        }
      }
    }
  }
}

.image {
  &-hero {
    overflow: hidden;
    position: relative;
    margin: -16px -16px 16px;

    &-article {
      height: 260px;
    }

    &-recommendations {
      height: 260px;
    }

    &-audio {
      height: 220px;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      text-align: center;

      img {
        object-fit: cover;
        flex-grow: 0;
      }
    }
  }
}

.text {
  margin-top: 20px;

  p {
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }
  }

  &-picture {
    margin: 16px -16px 20px;
    border-bottom: 1px solid $color-text-75;
    border-top: 1px solid $color-text-75;
  }
}

@media (min-width: 744px) {
  .text {
    &-picture {
      margin-left: 0;
      margin-right: 0;
      border-bottom: none;
      border-top: none;
    }
  }

  .list {
    &-arrows {
      &-block {
        &:hover {
          cursor: pointer;

          opacity: 0.7;
        }

        &:active {
          opacity: 0.5;
        }

        &.checked {
          pointer-events: none;
        }

        &.inactive {
          pointer-events: none;
        }

        &-arrow {
          padding-right: 0;
        }
      }
    }
  }

  .list {
    margin-right: 0;

    &-item {
      &:hover {
        cursor: pointer;

        opacity: 0.7;
      }

      &:active {
        opacity: 0.5;
      }

      &.checked {
        pointer-events: none;
      }

      &.inactive {
        pointer-events: none;
      }

      &-arrow {
        padding-right: 0;
      }
    }
  }

  .section {
    padding-top: 50px;

    &-title {
      font-size: 32px;
      line-height: 36px;

      &.desktop {
        display: block;
      }
    }

    &-banner {
      width: calc(100% + 32px);
      margin-top: -50px;
      margin-bottom: 20px;

      &--audio {
        audio {
          width: 500px;
        }
      }

      &--video {
        video {
          width: 500px;
        }
      }
    }

    &-article {
      &-audio {
        margin-top: -20px;
      }
    }

    &-switch {
      padding: 16px;
      border-bottom: none;

      &-question {
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  .image {
    &-hero {
      width: calc(100% + 32px);
      margin-top: -50px;
      margin-bottom: 20px;

      &-inner {
        img {
          width: 100%;
        }
      }
    }
  }
}

// .recommendations {
//   padding: 0 0 100px;
//   display: flex;
//   justify-content: center;

//   &-wrapper {
//     position: relative;
//     width: 100%;
//     max-width: 500px;

//     &-first {
//       max-width: 400px;
//     }
//   }

//   &-section {
//     margin-top: 30px;

//     &:first-child {
//       margin-top: 0;
//     }

//   }

//   &-title {
//     display: flex;
//     justify-content: space-between;
//     align-items: baseline;
//   }

//   &-list {
//     margin-top: 12px;

//     &-item {
//       color: $color-text-100;
//       display: flex;
//       align-items: center;
//       text-decoration: none;
//       margin-top: 16px;

//       &:first-child {
//         margin-top: 0;
//       }
//     }

//     &-picture {
//       position: relative;
//       height: 90px;
//       width: 90px;
//       border-radius: 22px;
//       overflow: hidden;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       background-image: url("../images/background-pear.jpg");
//       background-color: #FFF2E4; /* Used if the image is unavailable */
//       background-position: center;
//       background-repeat: no-repeat;
//       background-size: cover;
//       border: 1px solid $color-text-75;

//       img {
//         height: 90px;
//         width: 90px;
//       }

//       &-time {
//         position: absolute;
//         top: 6px;
//         right: 8px;
//         color: #fff;
//         font-size: 12px;
//       }

//       &-background {
//         background-color: $color-text-100;
//         opacity: .5;
//         width: 100%;
//         height: 100%;
//         position: absolute;
//         top: 0;
//         left: 0;
//       }

//       &-play {
//         position: absolute;

//         img {
//           width: 100%;
//           height: 100%;
//         }
//       }

//       &-progress {
//         position: absolute;
//         bottom: 0;
//         left: 0;
//         height: 12px;
//         background: $color-additional-100;
//         width: 30%;
//       }

//       &-info {
//         width: 90px;
//         height: 90px;
//         display: flex;
//         align-items: center;
//         justify-content: center;
//         position: absolute;
//         display: none;

//         &.show {
//           display: flex;
//         }
//       }
//     }

//     &-text {
//       // flex: 1;
//       margin-left: 10px;
//       width: calc(100% - 100px);

//       &-info {
//         display: flex;
//         align-items: center;
//         margin-top: 4px;

//         .attention {
//           display: none;

//           &.show {
//             display: block;
//           }
//         }
//       }

//       &-type {
//         display: flex;
//         font-size: 12px;
//         color: $color-text-75;
//         align-items: center;

//         span {
//           margin-left: 2px;
//           margin-right: 4px;
//         }

//       }

//       &-icon {
//         height: 10px;
//         width: 12px;
//         margin-right: 4px;

//         &.video {
//           background-image: url("../images/icons/youtube.svg");
//         }

//         &.audio {
//           background-image: url("../images/icons/headphones.svg");
//         }

//         &.reading {
//           background-image: url("../images/icons/book.svg");
//         }
//       }

//       &-time {
//         margin-left: 6px;
//       }

//       &-description {
//         font-size: 14px;
//         line-height: 1.3;
//         color: $color-text-75;
//         margin-right: 8px;
//         // margin-top: 4px;
//         // text-overflow: ellipsis;
//         // overflow: hidden;
//         // white-space: nowrap;
//       }
//     }
//   }

//   &-block {
//     flex-wrap: nowrap;
//     overflow: auto;
//     -webkit-overflow-scrolling: touch;
//     -ms-overflow-style: none;
//     scrollbar-width: none;
//     overflow-x: auto;
//     overflow-y: hidden;
//     display: flex;
//     scroll-snap-type: x mandatory;
//     scroll-behavior: smooth;
//     scroll-padding-left: 6px;
//     align-items: stretch;
//     // margin-top: 10px;

//     &::-webkit-scrollbar {
//       display: none;
//     }

//     scrollbar-width:none;

//   }

//   &-item {

//     padding: 0 5px 8px;
//     flex: none;

//     &:first-child {
//       margin-left: 11px;
//     }

//     &:last-child {
//       margin-right: 11px;
//     }

//     &-header {
//       line-height: 1.3;
//       position: absolute;
//       width: calc(100% - 32px);
//     }

//     &-link {
//       display: -webkit-flex;
//       display: flex;
//       flex-direction: column;
//       position: relative;
//       overflow: hidden;
//       width: 100%;
//       height: 100%;
//       border-radius: 12px;
//       backface-visibility: hidden;
//       transform: translateZ(0);
//       transition: all .25s ease-out;
//       color: inherit;
//       text-decoration: none;
//       border: 1px solid $color-text-50;
//     }

//     &-inner {
//       display: block;
//       width: 100%;
//       position: relative;
//       /* padding-top: 100%; */
//       overflow: hidden;
//       height: 100%;
//     }

//     &-cover {
//       display: flex;
//       flex-direction: column;
//       justify-content: space-between;
//       padding: 10px 16px;
//       height: 100%;
//     }

//     &-title {
//       line-height: 1.3;

//       img {
//         // margin-right: 4px;
//         display: inline;
//       }

//       h6 {
//         display: inline;

//       }

//       h5 {
//         display: inline;
//         margin-right: 4px;
//       }

//     }

//     &-picture {
//       display: flex;
//       justify-content: flex-end;
//       align-items: flex-end;
//       height: 100%;

//       img {
//         max-height: 180px;
//       }
//     }

//     &-info {
//       margin-top: 4px;
//       font-size: 14px;

//       span {
//         margin-right: 4px;
//       }

//     }

//     &-3 {
//       width: calc((100% - 45px) / 2);
//       // height: 208px;
//       height: 120px;

//     }

//     &-2 {
//       width: calc((100% - 30px) / 2);
//       height: 208px;
//     }

//     &-1 {
//       width: calc(100% - 30px);
//       height: 220px;

//       .recommendations-item-picture {
//         justify-content: flex-end;

//         img {

//           max-height: 90%;
//         }

//       }
//     }

//     &-0 {
//       width: 100%;
//       height: 220px;
//       margin-top: 16px;

//       &-cover {
//         padding-top: 20px;
//         padding-bottom: 20px;
//       }

//       &-picture {
//         img {
//           max-height: 150px;
//           max-width: 220px;
//         }
//       }

//       &-link {
//         border-radius: 20px;
//       }
//     }

//     &-short {
//       width: 70%;
//       height: inherit;

//       .recommendations-item-header {
//         line-height: 1.3;
//         position: inherit;
//         width: inherit;
//         z-index: 1;
//       }

//       .recommendations-item-picture {

//         justify-content: flex-end;

//         height: calc(100% - 20px);
//         position: absolute;
//         /* right: 16px; */
//         z-index: 0;
//         width: calc(100% - 32px);

//         img {

//           max-height: 100%;
//         }
//       }

//     }
//   }
// }

.cards {
  margin-top: 12px;
}

.card {
  width: 100%;
  height: 240px;
  margin-top: 12px;
  position: relative;

  &:first-child {
    margin-top: 0;
  }

  &-header {
    line-height: 1.3;
    position: absolute;
    width: calc(100% - 32px);
  }

  &-link {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transform: translateZ(0);
    transition: all 0.25s ease-out;
    color: inherit;
    text-decoration: none;
    border-radius: 40px;
    border: 1px solid $color-text-75;
  }

  &-background {
    background-image: url('../images/background-blood.jpg');
    background-color: #fff2e4; /* Used if the image is unavailable */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &-inner {
    display: block;
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100%;
  }

  &-cover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 24px 20px;
    height: 100%;
  }

  &-title {
    line-height: 1.3;

    img {
      display: inline;
    }

    h6 {
      display: inline;
    }

    h5 {
      display: inline;
      margin-right: 4px;
    }
  }

  &-picture {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;

    img {
      max-height: 150px;
      max-width: 220px;
    }
  }

  &-info {
    margin-top: 4px;
    font-size: 14px;
    text-decoration: underline;

    // span {
    //   margin-right: 4px;
    // }
  }

  &-tagline {
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
    color: $color-text-75;
    margin-bottom: 4px;
  }

  &-button {
    height: 78px;
    background-color: #efeff0;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text-100;
    font-size: 18px;
    border: 1px solid $color-text-75;
    border-radius: 39px;
    width: 100%;
    text-decoration: none;
    opacity: 0.95;
    position: absolute;
    bottom: 0;

    img {
      margin-right: 6px;
    }

    &:focus {
      outline: none;
    }

    &:active {
      opacity: 0.7;
    }

    &:visited {
      color: $color-text-100 !important;
    }
  }
}

.notifications {
  padding-bottom: 10px;
  display: none;
  justify-content: center;

  &.show {
    display: flex;
  }

  &-wrapper {
    width: 100%;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}

.notification {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  &:first-child {
    margin-top: 0;
  }

  &-left {
    display: flex;
    align-items: center;
    flex: 1;
  }

  &-text {
    flex: 1;
    font-size: 14px;
    line-height: 18px;
    padding-left: 6px;
    color: $color-text-75;
  }

  &-image {
    width: 18px;
    height: 18px;
    // background-image: url("../images/icons/notification-alert.svg");
  }

  &-button {
    background-color: transparent;
    border: none;
    font-size: 18px;
    color: $color-text-100;

    &:focus {
      outline: none;
    }
  }

  &.alert {
    background-color: $color-secondary-10;
    padding: 10px;
    border-radius: 12px;

    .notification-image {
      background-image: url('../images/icons/notification-alert.svg');
    }
  }
}

@media (min-width: 744px) {
  .notifications {
    padding-bottom: 0;
    margin-bottom: -20px;
    padding-top: 40px;
  }

  .card {
    height: 260px;
    &-picture {
      img {
        max-width: 250px;
      }
    }

    &-button {
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }

      &:active {
        opacity: 0.9;
      }
    }
  }

  // .recommendations {
  //   padding-top: 50px;

  //   &-section {
  //     margin-top: 50px;
  //   }

  //   &-title {

  //     h2 {
  //       font-size: 32px;
  //       line-height: 36px;
  //     }

  //   }

  //   &-item {

  //     &:first-child {
  //       margin-left: -5px;
  //     }

  //     &:last-child {
  //       margin-right: -5px;
  //     }

  //     &-3 {
  //       width: 170px;
  //     }

  //     &-2 {
  //       width: 255px;
  //       height: 253px;

  //       .recommendations-item-picture img {
  //         max-height: 70%;
  //       }
  //     }

  //     &-1 {
  //       padding: 0;
  //       margin-right: 0 !important;
  //       margin-left: 0 !important;
  //       height: 250px;
  //       width: 100%;

  //       .recommendations-item-picture img {
  //         max-height: 90%;
  //       }
  //     }

  //     &-0 {
  //       height: 240px;

  //       &-picture {
  //         img {
  //           max-width: 250px;
  //         }
  //       }
  //     }

  //     &-short {
  //       min-height: inherit;
  //       height: inherit;
  //       width: 255px;
  //     }

  //   }

  // }
}

.background {
  &-lavender {
    background: #d3d1ed !important;
  }

  &-lavenderdark {
    background: #c4c1eb !important;
  }

  &-lightred {
    background: #f8dfe5 !important;
  }

  &-lightgreen {
    background: #dfece6 !important;
  }

  &-green {
    background: #cce3de !important;
  }

  &-peach {
    background: #ffcdb2 !important;
  }

  &-biege {
    background: #fff2e4 !important;
  }

  &-primary {
    &-5 {
      background: $color-primary-5 !important;
    }

    &-10 {
      background: $color-primary-10 !important;
    }
  }

  &-text {
    &-5 {
      background: $color-text-5 !important;
    }

    &-25 {
      background: $color-text-25 !important;
    }
  }

  &-white {
    background: #fff;
  }
}

.angle {
  position: absolute;
  top: calc(50% - 4px);
  display: none;
  transform: translateY(-50%);
  z-index: 1;

  &-left {
    left: -20px;
  }

  &-right {
    right: -20px;
  }

  &-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border-color: transparent;
    border-width: 2px;
    background: #f4f4f4;
    box-shadow: rgba(0, 0, 0, 0.14) 0px 1px 1px 1px;
    touch-action: manipulation;
    cursor: pointer;
  }
}

.more {
  font-size: 14px;
  text-decoration-line: underline;
  opacity: 0.7;
  margin-left: 10px;
  display: flex;
  flex-wrap: nowrap;
}

.subtitle {
  font-size: 14px;
  opacity: 0.75;
}

.downloads {
  display: none;
  align-items: center;
  justify-content: space-between;

  &-progress {
    display: flex;
    align-items: center;
  }

  &.show {
    display: flex;
  }
}

@media (min-width: 744px) {
  .more {
    &:hover {
      cursor: pointer;
      opacity: 1;
    }

    &:active {
      opacity: 0.5;
    }
  }

  .angle.show {
    display: block;
  }
}

.questionnaire-final-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 20px;

  &-item {
    margin-bottom: 12px;
    font-weight: 700;
  }

  img {
    display: inline;
  }
}

.myday {
  padding: 0 0 100px;
  display: flex;
  justify-content: center;

  &-section {
    margin-top: 50px;

    &:first-child {
      margin-top: 0;
    }

    &-title {
      margin-bottom: 20px;
      display: flex;
      justify-content: center;
      color: $color-text-75;
    }
  }

  &-block {
    padding: 28px 20px 22px;
    border-radius: 20px;
    margin-top: 12px;

    &:first-child {
      margin-top: 0;
    }

    &-title {
      h5 {
        display: inline;
      }

      img {
        display: inline;
        vertical-align: text-top;
        margin-left: 4px;
      }

      .attention {
        margin-left: 4px;
      }
    }
  }

  &-list {
    margin-top: 16px;

    &-item {
      display: block;
      position: relative;
      margin-bottom: 12px;
      padding-left: 30px;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      &:last-child {
        margin-bottom: 0;
      }

      img {
        position: absolute;
        left: 3px;
        top: 7px;
        display: none;
      }

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .myday-list-item-checkbox {
          background-color: $color-text-100;
        }

        &:checked ~ .myday-list-item-checkmark {
          display: block;
          z-index: 1;
        }

        &:checked ~ .myday-list-item-action {
          text-decoration: line-through;
        }
      }

      &-checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid $color-text-100;
        border-radius: 2px;
        background-color: #fff;
        position: absolute;
        left: 0;
        top: 3px;
      }

      &-action {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
        width: 100%;
        text-align: left;
      }
    }
  }
}

@media (min-width: 744px) {
  .myday {
    padding-top: 50px;

    &-section {
      &-title {
        justify-content: flex-start;
        padding-left: 20px;
      }
    }

    &-list {
      &-item {
        &-action {
          cursor: pointer;
        }
      }
    }
  }
}

.settings {
  margin-top: 40px;

  .button-secondary {
    margin-top: 20;
  }
}

.confirmation {
  margin-top: 10px;
}

.recommendations-waiting-picture {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 32px;

  img {
    width: 80%;
    height: auto;
  }
}

.upload {
  width: calc(100% + 32px);
  background: $color-text-25;
  padding-top: 16px;
  align-items: center;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  margin-top: -16px;
  border-bottom: 1px solid $color-text-75;

  .section-switch {
    padding: 12px 16px 20px;
    margin: 0;
    width: 100%;
    max-width: 500px;
    border-bottom: 0;

    .switch {
      input:checked + i {
        background-color: #4bd763;
      }
      i {
        background-color: #f4f4f4;

        &::before {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .recommendations {
    padding: 0 0 100px;
    display: flex;
    justify-content: center;

    &-wrapper {
      position: relative;
      width: 100%;
      max-width: 500px;

      &-first {
        max-width: 400px;
      }
    }

    &-section {
      margin-top: 30px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
    }

    &-list {
      margin-top: 12px;

      &-item {
        color: $color-text-100;
        display: flex;
        align-items: center;
        text-decoration: none;
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }
      }

      &-picture {
        position: relative;
        height: 90px;
        width: 90px;
        border-radius: 22px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-image: url('../images/background-pear.jpg');
        background-color: #fff2e4; /* Used if the image is unavailable */
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border: 1px solid $color-text-75;

        img {
          height: 90px;
          width: 90px;
        }

        &-time {
          position: absolute;
          top: 6px;
          right: 8px;
          color: #fff;
          font-size: 12px;
        }

        &-background {
          background-color: $color-text-100;
          opacity: 0.5;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
        }

        &-play {
          position: absolute;

          img {
            width: 100%;
            height: 100%;
          }
        }

        &-progress {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 12px;
          background: $color-additional-100;
          width: 30%;
        }

        &-info {
          width: 90px;
          height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          display: none;

          &.show {
            display: flex;
          }
        }
      }

      &-text {
        // flex: 1;
        margin-left: 10px;
        width: calc(100% - 100px);

        &-info {
          display: flex;
          align-items: center;
          margin-top: 4px;

          .attention {
            display: none;

            &.show {
              display: block;
            }
          }
        }

        &-type {
          display: flex;
          font-size: 12px;
          color: $color-text-75;
          align-items: center;

          span {
            margin-left: 2px;
            margin-right: 4px;
          }
        }

        &-icon {
          height: 10px;
          width: 12px;
          margin-right: 4px;

          &.video {
            background-image: url('../images/icons/youtube.svg');
          }

          &.audio {
            background-image: url('../images/icons/headphones.svg');
          }

          &.reading {
            background-image: url('../images/icons/book.svg');
          }
        }

        &-time {
          margin-left: 6px;
        }

        &-description {
          font-size: 14px;
          line-height: 1.3;
          color: $color-text-75;
          margin-right: 8px;
          // margin-top: 4px;
          // text-overflow: ellipsis;
          // overflow: hidden;
          // white-space: nowrap;
        }
      }
    }

    &-block {
      flex-wrap: nowrap;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      scrollbar-width: none;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      scroll-snap-type: x mandatory;
      scroll-behavior: smooth;
      scroll-padding-left: 6px;
      align-items: stretch;
      // margin-top: 10px;

      &::-webkit-scrollbar {
        display: none;
      }

      scrollbar-width: none;
    }

    &-item {
      padding: 0 5px 8px;
      flex: none;

      &:first-child {
        margin-left: 11px;
      }

      &:last-child {
        margin-right: 11px;
      }

      &-header {
        line-height: 1.3;
        position: absolute;
        width: calc(100% - 32px);
      }

      &-link {
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 100%;
        border-radius: 12px;
        backface-visibility: hidden;
        transform: translateZ(0);
        transition: all 0.25s ease-out;
        color: inherit;
        text-decoration: none;
        border: 1px solid $color-text-50;
      }

      &-inner {
        display: block;
        width: 100%;
        position: relative;
        /* padding-top: 100%; */
        overflow: hidden;
        height: 100%;
      }

      &-cover {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px 16px;
        height: 100%;
      }

      &-title {
        line-height: 1.3;

        img {
          // margin-right: 4px;
          display: inline;
        }

        h6 {
          display: inline;
        }

        h5 {
          display: inline;
          margin-right: 4px;
        }
      }

      &-picture {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;

        img {
          max-height: 180px;
        }
      }

      &-info {
        margin-top: 4px;
        font-size: 14px;

        span {
          margin-right: 4px;
        }
      }

      &-3 {
        width: calc((100% - 45px) / 2);
        // height: 208px;
        height: 120px;
      }

      &-2 {
        width: calc((100% - 30px) / 2);
        height: 208px;
      }

      &-1 {
        width: calc(100% - 30px);
        height: 220px;

        .recommendations-item-picture {
          justify-content: flex-end;

          img {
            max-height: 90%;
          }
        }
      }

      &-0 {
        width: 100%;
        height: 220px;
        margin-top: 16px;

        &-cover {
          padding-top: 20px;
          padding-bottom: 20px;
        }

        &-picture {
          img {
            max-height: 150px;
            max-width: 220px;
          }
        }

        &-link {
          border-radius: 20px;
        }
      }

      &-short {
        width: 70%;
        height: inherit;

        .recommendations-item-header {
          line-height: 1.3;
          position: inherit;
          width: inherit;
          z-index: 1;
        }

        .recommendations-item-picture {
          justify-content: flex-end;

          height: calc(100% - 20px);
          position: absolute;
          /* right: 16px; */
          z-index: 0;
          width: calc(100% - 32px);

          img {
            max-height: 100%;
          }
        }
      }
    }
  }
}

.download-block {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  &-left {
    margin-right: 16px;
  }
}

@media (min-width: 744px) {
  .upload {
    width: calc(100% + 32px);
    margin-top: -50px;

    .section-switch {
      padding-left: 0;
    }

    .recommendations-item-3 {
      height: 160px;
    }
  }

  .recommendations-waiting-picture {
    margin-top: 48px;
    justify-content: flex-start;
    img {
      width: 60%;
      height: auto;
    }
  }
}

.tabs {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efeff0;
  margin-right: -16px;
}

.tab {
  color: #1d1e2c;
  text-decoration: none;
  margin-right: 24px;
  padding: 16px 0 8px;
  opacity: 0.75;

  &.active {
    opacity: 1;
    font-weight: 700;
    border-bottom: 2px solid #1d1e2c;
  }
}

@media (min-width: 744px) {
  .tabs {
    margin-right: 0;
  }

  .tab:hover {
    opacity: 1;
  }
}

.switch {
  display: flex;
  align-items: center;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}

.switch i {
  position: relative;
  display: inline-block;
  margin-right: 0.5rem;
  width: 51px;
  height: 31px;
  background-color: #d4d4d6;
  border-radius: 26px;
  vertical-align: text-bottom;
  transition: all 0.3s linear;
}
.switch i::before {
  content: '';
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  background-color: #d4d4d6;
  border-radius: 11px;
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.25s linear;
}
.switch i::after {
  content: '';
  position: absolute;
  left: 0;
  width: 27px;
  height: 27px;
  background-color: #fff;
  border-radius: 14px;
  box-shadow:
    0px 3px 8px rgba(0, 0, 0, 0.15),
    0px 3px 1px rgba(0, 0, 0, 0.06);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
}
.switch:active i::after {
  width: 28px;
  transform: translate3d(2px, 2px, 0);
}
.switch:active input:checked + i::after {
  transform: translate3d(16px, 2px, 0);
}
.switch input {
  display: none;
}
.switch input:checked + i {
  background-color: #4bd763;
}
.switch input:checked + i::before {
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.switch input:checked + i::after {
  transform: translate3d(22px, 2px, 0);
}

.popup-content-block {
  .image-hero {
    margin: initial;
    margin-bottom: 16px;
    width: 100%;
  }

  .section-article-audio {
    width: 100%;
  }

  .section-banner--video {
    width: 100%;
    margin: initial;
    margin-bottom: 16px;
  }
}

.marker-legend {
  margin-top: 10px;
  display: flex;
  gap: 16px;

  &-item {
    display: flex;
    align-items: center;
    gap: 6px;
    cursor: pointer;

    &-text {
      border-bottom: 1px dashed;
      font-size: 14px;
    }

    &-color {
      width: 10px;
      height: 10px;

      border-radius: 10px;

      &.green {
        background: #38ee76;
      }

      &.yellow {
        background: #ffa500;
      }

      &.red {
        background: #ed2e2e;
      }
    }
  }
}

.marker-chart {
  margin-top: 28px;
}

.text--marker {
  margin-top: 24px !important;
}

.section-support {
  padding: 0 10px;
  justify-content: flex-end;
  flex: 1;
  overflow: hidden;

  &-input {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .section-wrapper {
    gap: 10px;
    overflow: scroll;
    height: calc(100vh - 164px);

    flex-wrap: nowrap;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    -ms-scroll-snap-type: y mandatory;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    align-items: stretch;
    scrollbar-width: none;
  }
}

.support {
  display: flex;
  flex-direction: column;

  &-wrapper {
    width: 100%;
    max-width: 640px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  &-icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #c7c7ca;
    width: 100%;
    border-bottom: 1px solid #565661;

    &-block {
      width: 100%;

      padding: 8px 16px;
      align-items: center;
      display: flex;
      justify-content: center;

      gap: 12px;
    }
  }

  &-icon {
    border: 1px solid #1d1e2c;
    width: 44px;
    height: 44px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
}

.support-input input {
  width: 100%;
  border-radius: 16px;
  border: 1px solid $color-text-25;
  padding: 8px 12px;
  -webkit-appearance: none;
  vertical-align: middle;
  font-size: 14px;
  flex: 1;

  &::placeholder {
    color: $color-text-25;
  }

  &:focus {
    outline: none;
  }

  // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  //   text-transform: uppercase;
  // }
}

.support-input {
  width: 100%;
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 10px;

  padding-bottom: calc(8px + env(safe-area-inset-bottom));

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
}

.support-button {
  width: 30px;
  height: 30px;
  background: #1d1e2c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  border: none;
}

.support-date {
  display: flex;
  justify-content: center;
  padding-top: 10px;

  &-block {
    padding: 2px 10px;
    background: #f4f4f4;
    border-radius: 20px;
    font-size: 10px;
    font-weight: 500;
    text-transform: uppercase;
    color: #8e8f95;
  }
}

.support-message {
  display: flex;
  gap: 8px;

  &-avatar {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 36px;
    border-radius: 50%;
    min-height: 36px;

    &_user {
      border: 1px solid #f6d7cf;
      color: #e07a5f;
      font-size: 18px;
      font-weight: 700;
    }
  }
  &-name {
    font-weight: 600;
    font-size: 18px;

    &_user {
      color: #e07a5f;
    }
  }

  &-notification {
    font-size: 12px;
    line-height: 1.3;
    font-weight: 700;
    padding-bottom: 2px;
    display: none;

    &.show {
      display: block;
    }
  }
}

@media (min-width: 744px) {
  .support {
    &-icons {
      width: 100%;
    }
    &-input {
      padding: 8px 0;
    }
  }

  .section-support-input {
    padding-bottom: 30px;
  }
  .section-support {
    .section-wrapper {
      height: calc(100vh - 218px);
    }
  }
}

.heading {
  &-h2 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 900;
  }

  &-h4 {
    font-size: 24px;
    line-height: 28px;
    font-weight: 900;
  }

  &-h5 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 900;
  }

  &-h6 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }
}

.mt {
  &-2 {
    margin-top: 2px !important;
  }

  &-4 {
    margin-top: 4px !important;
  }

  &-12 {
    margin-top: 12px !important;
  }

  &-16 {
    margin-top: 16px !important;
  }

  &-20 {
    margin-top: 20px !important;
  }

  &-30 {
    margin-top: 30px !important;
  }

  &-32 {
    margin-top: 32px !important;
  }

  &-40 {
    margin-top: 40px !important;
  }

  &-50 {
    margin-top: 50px !important;
  }
}

.mr {
  &-6 {
    margin-right: 6px !important;
  }
}

.pt {
  &-16 {
    padding-top: 16px !important;
  }

  &-50 {
    padding-top: 50px !important;
  }
}

.transparent {
  background-color: transparent;
}
