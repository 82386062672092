.popup-2 {
  max-width: 400px;
  min-width: 200px;
  color: white;
  border-radius: 12px;
  background: #1d1e2c;

  &__heading {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &__description {
    color: rgb(255 255 255 / 80%);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    display: inline-block;
    vertical-align: middle;
    white-space: pre-wrap;
  }

  &__container {
    padding-left: 16px;
    padding-right: 16px;
  }

  &:not(&--modal) &__arrow {
    filter: drop-shadow(0 3px 6px rgb(209 209 209 / 100%));
  }

  &__footer {
    height: 40px;
    border-bottom: 1px solid rgb(209 209 209 / 100%);
  }

  &__actions {
    display: flex;
    gap: 8px;
    padding: 0 8px 8px;
  }

  &__close-btn {
    background: transparent;
    border: none;
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    padding: 10px 12px;
    color: white;

    &:hover {
      color: #f4f4f4;
    }
  }

  &__body {
    padding-top: 12px;
    padding-bottom: 4px;
  }
}

.popup-2__overlay {
  z-index: 2000;
  overflow: hidden !important;
  background: rgb(0 0 0 / 45%);
  animation-duration: 400ms;
  animation-fill-mode: both;
  animation-iteration-count: 1;
  animation-name: keyframe-modal-overlay-appearing;
}
