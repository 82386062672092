.page {
  margin-top: 16px;
  margin-bottom: 16px;

  &.noMT {
    margin-top: 0;
  }

  @media screen and (width >= 774px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.contentSpacing {
  &.margin {
    margin-left: 16px;
    margin-right: 16px;
  }

  &.padding {
    padding-left: 16px;
    padding-right: 16px;
  }
}

.contentContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  &.md {
    max-width: 532px;
  }

  &.lg {
    max-width: 632px;
  }

  &.xl {
    max-width: 740px;
  }
}
