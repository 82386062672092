.uploading-u-a-d-card {
  &__progress {
    position: static !important;
    background-color: #e2f2f6 !important;
    margin-bottom: 10px;
  }

  // &__indicators {
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }

  &__loader {
    width: 100%;
  }
}
