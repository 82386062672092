.overview-page-skeleton {
  &__date {
    width: 250px;
  }

  .card {
    line-height: 1;
  }

  .card-header {
    position: relative;
    z-index: 1;

    & > * {
      box-shadow: 0 0 5px 1px #e5e5e5;
    }

    .card-tagline {
      width: 30%;
    }

    .card-info {
      width: 50%;
    }
  }

  &__card-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .recommendations-list-picture {
    background-image: none;
    background-color: unset;
  }

  .recommendations-list-text-description {
    width: 50%;
  }

  &__list-image {
    line-height: 1;
    width: 100%;
    height: 100%;
  }
}
